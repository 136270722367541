import { initContract } from "@ts-rest/core";
import { z } from "zod";
import { ContentWriterType, SuggestCommentTone } from "../graphql";

const c = initContract();

export const aiContract = c.router({
  generatePiece: {
    method: "POST",
    path: "/ai/generate",
    metadata: {
      streams: true,
    },
    body: z
      .object({
        type: z.literal(ContentWriterType.Comment),
        clientId: z.string(),
        focus: z.string(),
        audience: z.string(),
        tone: z.nativeEnum(SuggestCommentTone),
        spokespersonId: z.string().nullable().optional(),
        length: z.number(),
        contextArticleIds: z.array(z.string()).optional(),
      })
      .or(
        z.object({
          type: z.literal(ContentWriterType.Article),
          clientId: z.string(),
          topic: z.string(),
          title: z.string(),
          contextArticleIds: z.array(z.string()).optional(),
        })
      )
      .or(
        z.object({
          type: z.literal(ContentWriterType.BlogPost),
          clientId: z.string(),
          topic: z.string(),
          title: z.string(),
          contextArticleIds: z.array(z.string()).optional(),
        })
      )
      .or(
        z.object({
          type: z.literal(ContentWriterType.LinkedinPost),
          clientId: z.string(),
          topic: z.string(),
          contextArticleIds: z.array(z.string()).optional(),
        })
      )
      .or(
        z.object({
          type: z.literal(ContentWriterType.PressRelease),
          clientId: z.string(),
          topic: z.string(),
          title: z.string(),
          contextArticleIds: z.array(z.string()).optional(),
        })
      )
      .or(
        z.object({
          type: z.literal(ContentWriterType.GenericComment),
          clientId: z.string(),
          articleId: z.string(),
          contextArticleIds: z.array(z.string()).optional(),
        })
      )
      .or(
        z.object({
          type: z.literal(ContentWriterType.Pitch),
          clientId: z.string(),
          journalist: z.string(),
          focus: z.string(),
          length: z.number(),
          contextArticleIds: z.array(z.string()).optional(),
        })
      )
      .or(
        z.object({
          type: z.literal(ContentWriterType.TwitterPost),
          clientId: z.string(),
          topic: z.string(),
          contextArticleIds: z.array(z.string()).optional(),
        })
      )
      .or(
        z.object({
          type: z.literal(ContentWriterType.ArticleComment),
          clientId: z.string(),
          articleId: z.string(),
          demographic: z.string(),
          length: z.number(),
          tone: z.nativeEnum(SuggestCommentTone),
          spokespersonId: z.string().optional(),
          additionalInfo: z.string().optional(),
          contextArticleIds: z.array(z.string()).optional(),
        })
      ),
    responses: {
      200: c.type<ReadableStreamDefaultReader<Uint8Array>>(),
      401: z.object({
        message: z.string(),
        success: z.boolean(),
      }),
    },
  },
  chat: {
    method: "POST",
    path: "/ai/chat",
    metadata: {
      streams: true,
    },
    body: z.object({
      timezone: z.string(),
      history: z
        .object({
          role: z.enum(["system", "user", "assistant", "function"]),
          content: z.string(),
        })
        .array(),
    }),
    responses: {
      200: c.type<ReadableStreamDefaultReader<Uint8Array>>(),
    },
  },
});
